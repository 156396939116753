import { React, useState, useEffect } from "react"
import { satoshi } from "../text/satoshi"
import { vitalik } from "../text/vitalik"

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const satoshiImages = importAll(require.context('../assets/satoshi', false, /\.(png|jpe?g|svg)$/));


const Sentance = (props) => {
    const { id, text, spaces, style, minted, active, mint } = props;

    const [imMinted, setImMinted] = useState(false)

    useEffect(() => {
        // console.log(minted, id)
        if (minted.includes(id.toString())) {
            setImMinted(true)

        } else {
            setImMinted(false)
        }
    }, [minted])



    return <span key={id} onClick={(!imMinted ? () => {
        console.log("Minting id: ", id)
        mint(id)} : () => {
            // use instead of a tag in a button
            window.open(`https://opensea.io/assets/ethereum/0x5a9359266d2f9b138bad29a7d28922014330156b/${id}`, "_blank") || window.location.replace(`https://opensea.io/assets/ethereum/0x5a9359266d2f9b138bad29a7d28922014330156b/${id}`);
        })} className={(imMinted ? (active ? " opacity-25 hover:bg-red-100  " : " hover:bg-red-100  ") : " hover:bg-green-100 ") + "  hover:cursor-pointer " + style}>{text} {spaces > 0 ? (spaces === 1 ? <br /> : <><br /> <br /></>) : null} </span>

}

const Graphic = (props) => {
    const { id, text, style, minted, active, mint } = props;

    const [imMinted, setImMinted] = useState(false)

    useEffect(() => {
        // console.log(minted, id)
        if (minted.includes(id.toString())) {
            setImMinted(true)

        } else {
            setImMinted(false)
        }
    }, [minted])



    return <div className={style} key={id}>
        <img onClick={(!imMinted ? () => {
            console.log("Minting id: ", id)
            mint(id)} : () => {
                // use instead of a tag in a button
                window.open(`https://opensea.io/assets/ethereum/0x5a9359266d2f9b138bad29a7d28922014330156b/${id}`, "_blank") || window.location.replace(`https://opensea.io/assets/ethereum/0x5a9359266d2f9b138bad29a7d28922014330156b/${id}`);
            })} className={(imMinted ? (active ? " opacity-25 hover:shadow-red-100  " : " hover:shadow-red-100  ") : " hover:shadow-green-100 ") + " hover:shadow-xl  hover:cursor-pointer "} src={satoshiImages[`satoshi${text}.png`]} />
    </div>

}

const Bitcoin = (props) => {

    const { minted, active, mint } = props;

    return <div className="w-screen ">

        <div className="w-screen flex justify-center mt-12  mb-20">
            <div className="relative text-left shadow-xl p-10 px-20  font-serif" style={{ maxWidth: "800px" }}>
                {satoshi.map((val, index) => {
                    // abstract
                    if (index === 4) {
                        return <p className="ease mx-10 md:mx-24 mt-10 mb-5 text-sm" >
                            {val.map((next) => {
                                let finalText = next.text;
                                let extraStyles = ""
                                if (finalText.includes("{")) {
                                    finalText = finalText.split("{")[0]
                                    finalText += "."
                                    extraStyles += " font-bold"
                                } else {
                                    finalText += "."
                                }
                                finalText = finalText.replaceAll("&", ".")
                                return <Sentance mint={mint} text={finalText} id={next.id} style={extraStyles} minted={minted} active={active} />



                            })}
                        </p>
                    }

                    let style = " "
                    if (val[0].text.includes("{")) {
                        let split = val[0].text.split("{")
                        let text = split[0]
                        switch (split[1]) {
                            case "title}":

                                style += "text-2xl font-bold mb-5 text-center mt-8"
                                break;
                            case "author}":

                                style += "text-sm text-center"
                                break;
                            case "header}":
                                text = text.replace(":", ".")
                                style += "text-xl my-4 font-semibold"
                                break;
                            case "sub-header}":

                                style += "text-lg mt-10 font-semibold"
                                break;
                            case "ref}":

                                style += "my-2"
                                break;
                            case "image}":

                                text = text.replace("image", "").trim()
                                let imageStyles = "mx-20 my-10 flex justify-center"

                                if (parseInt(text) > 7) {
                                    imageStyles = "my-8 w-3/5"
                                }

                                return  <Graphic mint={mint} text={text} id={val[0].id} style={imageStyles} minted={minted} active={active} />
                              
                                break;
                            case "bullet}":
                                if (text.includes(1)) { style += " mt-5 " }
                                if (text.includes(6)) { style += " mb-5 " }
                                style += " ml-5 block "
                                break;
                        }
                        text = text.replaceAll("&", ".")
                        return <p className={style}>
                            <Sentance mint={mint} text={text} id={val[0].id} style={style} minted={minted} active={active} />

                        </p>
                    } else {
                        return <p>
                            {val.map((next) => {
                                let finalText = "";
                                let extraStyle = "";
                                if (next.text.charAt(next.text.length - 1) === ":") {
                                    finalText = next.text
                                } else {
                                    finalText = next.text + "."
                                }
                                finalText = finalText.replaceAll("&", ".")

                                if (finalText.includes("#")) {
                                    extraStyle += " ml-5"
                                    finalText = finalText.replace("#", "")
                                }

                                if (finalText.includes("//")) {
                                    finalText = finalText.replace("//", "")
                                    return <Sentance mint={mint} spaces={1} text={finalText} id={next.id} style={extraStyle} minted={minted} active={active} />
                                } else {
                                    return <Sentance mint={mint} text={finalText} id={next.id} style={extraStyle} minted={minted} active={active} />
                                }


                            })}
                        </p>
                    }

                })}
            </div>

        </div>



    </div>
}
export default Bitcoin