import {
  SpeakerphoneIcon
} from "@heroicons/react/outline";

const NetworkWarning = (props) => {
  const { account, chainId, switchEthereumChain, correctChain } = props;
  return <>
    {account !== null && chainId && chainId !== correctChain ? <div style={{ width: "100%" }} className="mb-5 h-12 bg-black text-white flex justify-center font-mono">
      <button onClick={() => switchEthereumChain()} className="flex flex-row items-center justify-center w-full" style={{ maxWidth: "1100px" }}>
        <div className="flex items-center space-x-2 flex-row">
          <div className="p-2 bg-header-font rounded-lg bg-opacity-40">
            <SpeakerphoneIcon className="h-5" />
          </div>
          <div className="flex flex-col sm:flex-row">
            <p className="text-sm">You are currently on a different network. Please click to switch to {correctChain === 1 ? "Ethereum Mainnet" : "Rinkeby Test Network"}.</p>
          
          </div>


        </div>

      </button>
    </div> : null}
  </>
}

export default NetworkWarning