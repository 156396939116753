import { React, useState, useEffect } from "react"
// import { satoshi } from "../text/satoshi"
import { vitalik } from "../text/vitalik"
import eth from "../assets/eth.png"


function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const vitalikImages = importAll(require.context('../assets/vitalik', false, /\.(png|jpe?g|svg)$/));

const Sentance = (props) => {
    const { id, text, spaces, style, minted, active, mint } = props;

    const [imMinted, setImMinted] = useState(false)

    useEffect(() => {
       
        if (minted.includes(id.toString())) {
            console.log(minted, id)
            setImMinted(true)

        } else {
            setImMinted(false)
        }
    }, [minted])



    return <span key={id} onClick={(!imMinted ? () => {
        console.log("Minting id: ", id)
        mint(id)} : () => {
            // use instead of a tag in a button
            window.open(`https://opensea.io/assets/ethereum/0x5a9359266d2f9b138bad29a7d28922014330156b/${id}`, "_blank") || window.location.replace(`https://opensea.io/assets/ethereum/0x5a9359266d2f9b138bad29a7d28922014330156b/${id}`);
        })} className={(imMinted ? (active ? " opacity-25 hover:bg-red-100  " : " hover:bg-red-100   ") : " hover:bg-green-100   ") + "  hover:cursor-pointer " + style}>{text} {spaces > 0 ? (spaces === 1 ? <br /> : <><br /> <br /></>) : null} </span>

}

const Graphic = (props) => {
    const { id, text, style, minted, active, mint } = props;

    const [imMinted, setImMinted] = useState(false)

    useEffect(() => {
        // console.log(minted, id)
        if (minted.includes(id.toString())) {
            setImMinted(true)

        } else {
            setImMinted(false)
        }
    }, [minted])

    

    return <div className={style} key={id}>
        <img onClick={(!imMinted ? () => {
            console.log("Minting id: ", id)
            mint(id)} : () => {
                // use instead of a tag in a button
                window.open(`https://opensea.io/assets/ethereum/0x5a9359266d2f9b138bad29a7d28922014330156b/${id}`, "_blank") || window.location.replace(`https://opensea.io/assets/ethereum/0x5a9359266d2f9b138bad29a7d28922014330156b/${id}`);
            })} className={(imMinted ? (active ? " opacity-25 hover:shadow-red-100  " : " hover:shadow-red-100  ") : " hover:shadow-green-100 ") + " hover:shadow-xl  hover:cursor-pointer "} src={vitalikImages[`vitalik${text}.png`]} />
    </div>

}



const Eth = (props) => {

    const { minted, active, mint } = props;
   
    return <div className="w-screen relative sm:flex flex-col items-center justify-center mt-12  mb-20 z-0" style={{ width: "100vw" }}>
        <div className="relative text-left sm:shadow-xl sm:p-10 sm:px-24 text-gray-600 text-xs block" style={{ maxWidth: "800px" }}>
            <img src={eth} className="opacity-25 absolute h-14 top-14 left-8" />
            {vitalik.map((val, index) => {
                // abstract

                let style = " mx-3 sm:mx-0 "
                // console.log(val)
                if (val[0].text.includes("{")) {
                    let split = val[0].text.split("{")
                    let text = split[0]
                    let notRef = true
                    switch (split[1]) {
                        case "title}":

                            style += "text-sm font-bold text-black mt-8 block"
                            break;
                        case "author}":

                            style += "text-sm font-bold text-black mb-20"
                            break;
                        case "header}":
                            text = text.replace(":", ".")
                            style += "text-lg my-4"
                            break;
                        case "bold}":
                            text = text.replace(":", ".")
                            style += "font-bold my-4"
                            break;
                        case "tableHeader}":

                            style = "italic my-4 mt-10 mx-3 sm:mx-0 "
                            break;
                        case "listHeader}":

                            style = "my-2 block ml-2"
                            break;
                        case "listItem}":

                            style = "my-2 ml-5"
                            break;
                        case "ref}":
                            notRef = false
                            style += "my-2"
                            break;
                        case "image}":
                            // console.log(text)
                            text = text.replace("image", "").trim()
                            let imageStyles = " my-10 flex justify-center"
                            let num = parseInt(text)
                            if (num === 2 || num === 3 || num === 4 || num === 8 || num === 10 || num === 12 || num === 13) {
                                imageStyles = " ml-3 my-3"
                            }
                            // console.log(num)
                            return <Graphic mint={mint} text={text} id={val[0].id} style={imageStyles} minted={minted} active={active} />


                    }
                    if (text.includes("#") && notRef) {
                        style += " ml-5"
                        text = text.replace("#", "")
                    }
                    text = text.replaceAll("&", ".")
                    // console.log(val)
                    return <p className={style}>
                        {/* <span onClick={() => { console.log("You clicked ", val.id) }} className={"hover:bg-green-100  hover:shadow-green-100 hover:shadow-lg hover:cursor-pointer " + style}>{text}</span> */}
                        <Sentance mint={mint} text={text} id={val[0].id} style={style} minted={minted} spaces={0} active={active} />
                    </p>
                } else {
                    return <p className="leading-loose mx-5 sm:mx-0">
                        {val.map((next) => {
                            let finalText = "";
                            let extraStyle = "";
                            if (next.text.charAt(next.text.length - 1) === ":") {
                                finalText = next.text
                            } else {
                                if (next.text.includes("//") && next.text.charAt(next.text.length - 3) === ":") {
                                    finalText = next.text
                                } else if (next.text.includes("~") && next.text.charAt(next.text.length - 2) === ":") { finalText = next.text 
                                } else if (next.text.charAt(next.text.length - 1) === "?") {
                                    finalText = next.text
                                } else {
                                    finalText = next.text + "."
                                }
                            }
                            finalText = finalText.replaceAll("&", ".")

                            if (finalText.includes("#")) {
                                if (finalText.includes("##")) {
                                    extraStyle += " ml-10"
                                } else {
                                    extraStyle += " ml-5"
                                }

                                finalText = finalText.replaceAll("#", "")
                            }

                            if (finalText.includes("//")) {
                                finalText = finalText.replace("//", "")

                                return <Sentance mint={mint} spaces={2} text={finalText} id={next.id} style={extraStyle} minted={minted} active={active} />
                            } else if (finalText.includes("~")) {
                                finalText = finalText.replace("~", "")
                                return <Sentance mint={mint} spaces={1} text={finalText} id={next.id} style={extraStyle} minted={minted} active={active} />
                            } else {
                                return <Sentance mint={mint} text={finalText} id={next.id} style={extraStyle} minted={minted} active={active} />
                            }


                        })}
                    </p>
                }

            })}
        </div>

     

    </div>
}
export default Eth