
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Web3 from "web3";
import { toast } from "react-toastify";
import { useState, useEffect, useLayoutEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
// import Web3 from "web3";
import { ethers } from "ethers";
import swal from "sweetalert";

import contractABI from "../contracts/APOTSOrigins.json"
import Home from "../pages/Home";

function WebThreeWrapper() {
  const [curChainId, setCurChainId] = useState(null);
  const [method, setMethod] = useState(null);
  const [account, setAccount] = useState(null);
  const [stage, setStage] = useState(0)

  // mainnet = 1, Rinkeby = 4
  const correctChain = 1;
  const contractAddress = "0x5A9359266d2f9B138bAD29A7D28922014330156B";

  const [minted, setMinted] = useState([])
  const [mintMessage, setMintMessage] = useState("");
  // Stages
  // -1 = not started
  //  0 = OG
  //  1 = Allow List
  //  2 = Public
 


  useEffect(() => {
    account && method && fireToast();
  }, [method]);



  useEffect(async () => {
    loadWeb3();
  }, []);

  async function loadWeb3() {
    if (window.ethereum) {

      try {
        getCurrentAddressConnected();
        addAccountsAndChainListener();
        // loadBlockchainData();
      } catch (error) {
        console.error(error);
      }
    } else {
      swal(
        "",
        "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!",
        "error"
      );
    }
  }



  async function mint(tokenId) {
    if (!account) {
      connectMetaMask();
      return;
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(contractAddress, contractABI.abi, signer);
    if (contract) {
      if (minted.includes(tokenId)) {
        swal(
          "Token ID already Minted.",
          "",
          "error"
        );
        return
      }
      if (curChainId === correctChain) {
        if (stage > 0) {
          try {
            // let finalPrice = 0.01
            // console.log("final price:", finalPrice)
            console.log("Sending transaction, please wait.")
            setMintMessage("Sending transaction, please wait.")
            await contract.pickASentance(tokenId);
            setMintMessage("Minting, please check MetaMask for Completion.")
          } catch (error) {
            setMintMessage("")
            console.log(error)
            if (error.code === 4001) {
              swal("Transaction Rejected!", "", "error");
            } else {
              swal("Transaction Failed!", "", "error");
            }
          }
        } else {
          swal(
            "Mint has not started yet!",
            "",
            "error"
          );
        }
      } else {
        swal(`Please switch to ${correctChain === 1 ? "Ethereum mainnet" : "Rinkeby Test Network"} to mint.`, "", "error");
      }

    } else {
      swal(
        "",
        "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!",
        "error"
      );
    }
  }

  const getCurrentAddressConnected = async () => {
    const { ethereum } = window;

    if (ethereum) {
      try {
        const accounts = await ethereum.request({
          method: "eth_accounts",
        });
        if (accounts.length > 0) {
          setAccount(accounts[0].toLowerCase());
        }

        const provider = new ethers.providers.Web3Provider(ethereum);
        const { chainId } = await provider.getNetwork();
        setCurChainId(chainId);

        if (chainId === correctChain) {
          const signer = provider.getSigner();
          const contract = new ethers.Contract(contractAddress, contractABI.abi, signer);
          const arr = await contract.mintedIds();
          // console.log("arr recieved from contract: ", arr)
          let allMinted = []
          for (let i in arr) {
            if (Number(arr[i]) === 1) {
              allMinted.push(i)
            }
          }
          console.log("minted: ", allMinted)
          setMinted(allMinted)
          const isStarted = await contract.saleKey();
          setStage(isStarted)
        }



      } catch (error) {
        console.error(error);
      }
    }
  };

  const addAccountsAndChainListener = async () => {
    //this event will be emitted when the currently connected chain changes.
    window.ethereum.on("chainChanged", (_chainId) => {
      window.location.reload();
    });

    // this event will be emitted whenever the user's exposed account address changes.
    window.ethereum.on("accountsChanged", (accounts) => {
      window.location.reload();
    });
  };

  const switchEthereumChain = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${correctChain}` }],
      });
    } catch (e) {
      console.log(e.error);
    }
  };

  const connectMetaMask = async () => {
    const { ethereum } = window;

    if (ethereum) {
      try {
        let accounts;

        accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });

        console.log("Found an account! Address : ", accounts[0]);
        setAccount(accounts[0].toLowerCase());
        method && fireToast();

      } catch (error) {
        if (error.code === 4001) {
          swal("Request to access account denied!", "", "error");
        }
      }
    } else {
    }
  };

  const fireToast = () => {
    toast[method](
      `You are ${method === "error" ? "not" : ""
      } connected to ${correctChain === 1 ? "Ethereum mainnet" : "Rinkeby Test Network"}`,
      {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: true,
      }
    );
  };

  return (
    <Home
      chainId={curChainId}
      switchEthereumChain={switchEthereumChain}
      connectMetaMask={connectMetaMask}
      account={account}
      correctChain={correctChain}
      mint={mint}
      address={contractAddress}
      mintMessage={mintMessage}
      minted={minted}
    />
  );
}

export default WebThreeWrapper;
