import { useEffect, useState } from "react"
import Bitcoin from "./Bitcoin";
import Eth from "./Eth";
import ether from "../assets/eth.png"
import etherscan from "../assets/etherscan.png"
import btc from "../assets/bitcoin.png"
import { FilterIcon } from '@heroicons/react/solid'
import { useNavigate, useParams } from "react-router-dom";
import NetworkWarning from "../components/NetworkWarning";
import logo from "../assets/logo.png"

function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener("scroll", updateScrollDirection); // add event listener
        return () => {
            window.removeEventListener("scroll", updateScrollDirection); // clean up
        }
    }, [scrollDirection]);

    return scrollDirection;
};


const Home = (props) => {

    const { chainId, address, switchEthereumChain, minted, connectMetaMask, account, correctChain, mint } = props;

    const navigate = useNavigate();
    const [eth, setEth] = useState(false);
    const [bitcoin, setBitcoin] = useState(false)
    const [active, setActive] = useState(false)
    const scrollDirection = useScrollDirection();

    const [enterSite, setEnterSite] = useState(false)

    useEffect(() => {
        if (window.location.pathname.includes("eth")) {
            setEth(true);
        }
        if (window.location.pathname.includes("btc")) {
            setBitcoin(true);
        }

    }, [window.location.pathname]);

    useEffect(() => {
        navigate({ pathname: "/eth", search: "", });
        setEth(true)
    }, []);


    return <div className="relative">

        {!eth && !bitcoin ?



            <div className="flex  justify-center h-screen space-x-20">
                <div className="flex flex-col justify-center">
                    <button onClick={() => {
                        navigate({ pathname: "/eth", search: "", });
                        setEth(true)
                    }}><img src={ether} className="h-24 opacity-90 hover:opacity-100" /></button>
                </div>
                <div className="flex flex-col justify-center">
                    <button onClick={() => {
                        navigate({ pathname: "/btc", search: "", });
                        setBitcoin(true)
                    }}><img src={btc} className="h-24 opacity-90 hover:opacity-100" /></button>
                </div>
            </div> :
            <>
                {enterSite ? null : <div className="w-screen fixed bg-black bg-opacity-25 h-full z-20 flex justify-center  items-start">
                    <div className="bg-white fade-in rounded-lg  flex flex-col justify-center items-center shadow-2xl mt-20 z-20 p-12 space-y-5" style={{ maxWidth: "700px", fontFamily: "Blocky" }}>
                        <div className="flex justify-center">
                            <img src={logo} className="h-20 w-auto" />
                        </div>

                        <p className="text-3xl">A Piece of the Story</p>
                        <p className="text-xl sm:mx-16 ">A CC0 project commemorating the creators and origins of the space.</p>
                        <div className="text-left mx-5 sm:mx-16 space-y-3">
                            <p className=" underline text-lg">Instructions</p>
                            <p>1. Switch between whitepapers using icons in the top left.</p>
                            <p>2. Connect wallet. </p>
                            <p>3. Click sentances or graphics to mint.  </p>
                            <p className="space-x-4"><span>Note (on hover):</span> <span className="bg-green-100">Available</span> <span className="bg-red-100">Minted</span></p>
                        </div>
                        <div className="w-full">

                            <button onClick={() => setEnterSite(true)} className="text-white hover:text-black hover:bg-white hover:border-black border-solid border-2 bg-black w-1/2 my-5 text-xl rounded-lg py-2">Enter Site</button>
                        </div>
                    </div>
                </div>}

                <NetworkWarning account={account} chainId={chainId} correctChain={correctChain} switchEthereumChain={switchEthereumChain} />
                <div className={`sticky ${scrollDirection === "down" ? "-top-24" : "top-5"} left-0 flex flex-row items-center justify-between w-screen z-20 transition-all duration-500`} >
                    <div className="sm:w-1/3 flex justify-start space-x-5 pl-3 sm:pl-10  ">
                        <button onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth"
                            });
                            navigate({ pathname: "/eth", search: "", });
                            setEth(true)
                            setBitcoin(false)
                        }}><img src={ether} className={(eth ? " opacity-100 " : " opacity-50 ") + " h-10 hover:opacity-100"} /></button>
                        <button onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth"
                            });
                            navigate({ pathname: "/btc", search: "", });
                            setEth(false)
                            setBitcoin(true)
                        }}><img src={btc} className={(bitcoin ? " opacity-100 " : " opacity-50 ") + " h-10 hover:opacity-100"} /></button>
                    </div>
                    <div className="w-1/3 text-center  flex-col  hidden sm:flex">
                        <p className="" style={{ fontFamily: "blocky" }}>A Piece of the Story</p>
                    </div>

                    <div className="sm:w-1/3 text-center sm:text-right pr-10 flex flex-row justify-end items-center space-x-5">
                        <a href={`https://${correctChain === 4 ? "rinkeby." : ""}etherscan.io/address/${address}`} target="_blank"><img className="h-7 contrast-50 " src={etherscan} /></a>
                        {account !== null ? <button onClick={() => setActive(!active)}><FilterIcon className={`h-8 ${(!active ? "" : " opacity-25 ")} ${(bitcoin ? " text-orange-400 " : " text-gray-500 ")}`} /></button> : null}
                        {account === null ? <button onClick={() => connectMetaMask()} className={(bitcoin ? "  border-orange-400 text-orange-400 hover:bg-orange-400 " : " text-gray-500 border-gray-500 hover:border-gray-300 hover:bg-gray-500 ") + " border-solid border-2  py-1 px-2 rounded-lg  hover:text-white hover:borderhove-white"}>Connect Wallet</button>
                            : <p className={(bitcoin ? "  border-orange-400 text-white bg-orange-400 " : " text-white  border-gray-500 bg-gray-500 ") + " border-solid border-2  py-1 px-2 rounded-lg "}> {account.substring(0, 6)}...{account.substring(38, 42)}</p>}
                    </div>
                </div>
                {eth ? <Eth mint={mint} active={active} minted={minted} /> : <Bitcoin mint={mint} active={active} minted={minted} />}




            </>}



    </div>
}

export default Home